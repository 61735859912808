import { PLAYER_TYPE, PLAYER_TYPE_ID } from '@components/VideoPlayer/constants';
import { usePlayerStore } from '@components/VideoPlayer/store/usePlayerStore';
import { playerLogs } from '@components/VideoPlayer/Utils/helper';
import { checkIfFatalError, isiOS } from '@components/VideoPlayer/Utils/utils';
import { useStreamStore } from '@stores/streamStore';
import type { MediaPlayer as IMediaPlayer, PlayerError as IPlayerError, PlayerState as IPlayerState, Quality as IQuality } from 'amazon-ivs-player';
import { PlayerEventType, PlayerState } from 'amazon-ivs-player';
import * as AWSIVSPlayer from 'amazon-ivs-player';
import { useEffect, useRef, useState } from 'react';
import * as workerTimers from 'worker-timers';
function onPlaybackBlockHandler(type: PLAYER_TYPE) {
  // Here, we pause the stream
  const activePlayerType = usePlayerStore.getState().activePlayerType;
  if (activePlayerType === type) {
    useStreamStore.getState().setIsPlaying(false);
  }
}
const usePlayer = (src: string | null, params: {
  type: PLAYER_TYPE;
  isChannelLive: boolean;
  stream_infra_type?: number;
}) => {
  const {
    isChannelLive,
    type
  } = params;
  const isIVS_LLStream = (src || '').indexOf('loco_player_version=2') >= 0;
  const dLog = new playerLogs({
    type
  });
  const CONSOLE_LOG = (...rest: any) => dLog.log(`usePlayer: `, ...rest);
  const p_start_loaded_on_ref = useRef(0);
  const p_end_time_ref = useRef(0);
  const isLivePlayer = type === PLAYER_TYPE.LIVE;
  const IvsPlayerRef = useRef<IMediaPlayer | null>(null);
  const isValidSourceForInitialization = !!src && (isChannelLive || type === PLAYER_TYPE.VOD);

  // States
  const seekedPositionRef = useRef(0);
  const isPlayerPaused_ios_mweb = useRef(false);
  const currentPosition_ios_mweb = useRef(-1);
  const isSeekRequestedAgain_ios_mweb = useRef(false);
  const isSeekRequestedAgainPosition_ios_mweb = useRef(-1);
  const [isPlaybackBlocked, setIsPlaybackBlocked] = useState(false);
  const [activeState, setActiveState] = useState(PlayerState.IDLE);
  const [timeToLoad, setTimeToLoad] = useState(0);
  const [canInitializePlayer, setCanInitializePlayer] = useState(false);
  const [isPlayerInitialized, setIsPlayerInitialized] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isBuffering, setIsBuffering] = useState(false);
  const [isMuted, _setIsMuted] = useState(false);
  const [isAutoQualityMode, _setIsAutoQualityMode] = useState(true);
  const [quality, _setQuality] = useState<IQuality | null>(null);
  const [qualitites, _setQualitites] = useState<IQuality[]>([]);
  const [playbackRate, _setPlaybackRate] = useState(1);
  const [volume, _setIsVolume] = useState(0);
  const [isPaused, setIsPaused] = useState(true);
  const [isSeekbarSeekPending, setIsSeekbarSeekPending] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [hasEnded, setHasEnded] = useState(false);
  const [error, setError] = useState<null | IPlayerError>(null);
  const [internalError, setInternalError] = useState<null | (IPlayerError & {
    count?: number;
  })>(null);
  const [duration, setDuration] = useState(0);
  const [position, setPosition] = useState(0);
  const isInfiniteDuration = !isFinite(duration);
  const hasError = !!error;
  if (isPlayerPaused_ios_mweb.current === false) {
    currentPosition_ios_mweb.current = position;
  }
  const loadVideoPlayer = () => {
    const currentPlayer = IvsPlayerRef.current;
    if (!src || !currentPlayer) return;
    let urlToLoad = src;
    if (isChannelLive && type === PLAYER_TYPE.VOD) {
      const newURL = new URL(urlToLoad);
      newURL.searchParams.append('version', '3');
      urlToLoad = newURL.href;
    }
    currentPlayer.load(urlToLoad);
    if (isChannelLive && type === PLAYER_TYPE.LIVE || !isChannelLive && type === PLAYER_TYPE.VOD) {
      currentPlayer.play();
    }
  };
  // callbacks
  const onStateChange = (state: IPlayerState) => {
    {
      if (IvsPlayerRef.current) {
        const isMuted = IvsPlayerRef.current.isMuted();
        _setIsMuted(isMuted);
      }
    }
    setActiveState(state);
    CONSOLE_LOG('[STATE_CHANGE] :: state', state);
    if (state === PlayerState.READY) {
      setError(null);
      setInternalError(null);
    }
    if (state === PlayerState.READY && p_end_time_ref.current === 0) {
      // Setting fallback value to current time,
      // By anychance user exit & event fire at same time, then p_start_loaded_on_ref.current is 0

      p_start_loaded_on_ref.current = p_start_loaded_on_ref.current || Date.now();
      p_end_time_ref.current = Date.now() - p_start_loaded_on_ref.current;
      setTimeToLoad(p_end_time_ref.current);
    }
    const currentPlayer = IvsPlayerRef.current;
    if (currentPlayer) {
      _setQualitites(currentPlayer.getQualities());
      _setQuality(currentPlayer.getQuality());
      _setPlaybackRate(currentPlayer.getPlaybackRate());
      _setIsAutoQualityMode(currentPlayer.isAutoQualityMode());
    }
    if (isiOS() && isInfiniteDuration && type === PLAYER_TYPE.VOD && state === PlayerState.PLAYING) {
      if (isSeekRequestedAgain_ios_mweb.current === true && isSeekRequestedAgainPosition_ios_mweb.current >= 0) {
        const positionToSeek = isSeekRequestedAgainPosition_ios_mweb.current;
        // iOSm Web Playing, then we seek to a position
        isSeekRequestedAgain_ios_mweb.current = false;
        isSeekRequestedAgainPosition_ios_mweb.current = -1;
        CONSOLE_LOG('[iOS mWEB play/seek 2.0] :: data', positionToSeek);
        seekTo(positionToSeek, true);
      } else if (isPlayerPaused_ios_mweb.current === true && currentPosition_ios_mweb.current >= 0) {
        const positionToSeek = currentPosition_ios_mweb.current;
        // iOSm Web Playing, then we seek to a position
        isPlayerPaused_ios_mweb.current = false;
        currentPosition_ios_mweb.current = -1;
        CONSOLE_LOG('[iOS mWEB play/seek] :: data', positionToSeek);
        seekTo(positionToSeek);
      }
    }
    const _isPaused = state !== PlayerState.BUFFERING && state !== PlayerState.PLAYING;
    setIsPaused(_isPaused);
    setIsBuffering(state === PlayerState.BUFFERING);
    setHasEnded(state === PlayerState.ENDED);
  };
  const onStateChangeRef = useRef(onStateChange);
  onStateChangeRef.current = onStateChange;
  const play = () => {
    const currentPlayer = IvsPlayerRef.current;
    if (!currentPlayer) {
      return;
      // throw new Error('Player is not initialized')
    }

    return currentPlayer.play();
  };
  const pause = () => {
    const currentPlayer = IvsPlayerRef.current;
    if (!currentPlayer) {
      return;
      // throw new Error('Player is not initialized')
    }

    isPlayerPaused_ios_mweb.current = true; // now on play, we seek to same position again.
    return currentPlayer.pause();
  };
  const getPosition = () => {
    const currentPlayer = IvsPlayerRef.current;
    if (!currentPlayer) {
      throw new Error('Player is not initialized');
    }
    return currentPlayer.getPosition();
  };
  const setMuted = (mute: boolean) => {
    const currentPlayer = IvsPlayerRef.current;
    if (!currentPlayer) {
      // throw new Error('Player is not initialized')
      return;
    }
    return currentPlayer.setMuted(mute);
  };
  const setQuality = (quality: IQuality, adaptive?: boolean) => {
    const currentPlayer = IvsPlayerRef.current;
    if (!currentPlayer) {
      // throw new Error('Player is not initialized')
      return;
    }
    // _setQuality(quality)
    return currentPlayer.setQuality(quality, adaptive);
  };
  const setAutoQualityMode = (enable: boolean) => {
    const currentPlayer = IvsPlayerRef.current;
    if (!currentPlayer) {
      // throw new Error('Player is not initialized')
      return;
    }

    // _setIsAutoQualityMode(enable)
    return currentPlayer.setAutoQualityMode(enable);
  };
  const setPlaybackRate = (rate: number) => {
    const currentPlayer = IvsPlayerRef.current;
    if (!currentPlayer) {
      // throw new Error('Player is not initialized')
      return;
    }
    // _setPlaybackRate(rate)
    return currentPlayer.setPlaybackRate(rate);
  };
  const setVolume = (volume: number) => {
    const currentPlayer = IvsPlayerRef.current;
    if (!currentPlayer) {
      // throw new Error('Player is not initialized')
      return;
    }
    return currentPlayer.setVolume(volume);
  };
  const seekTo = (time: number, isRequested?: boolean) => {
    if (type === PLAYER_TYPE.LIVE) {
      throw new Error("Can't seek for live instance");
    }
    const currentPlayer = IvsPlayerRef.current;
    if (!currentPlayer) {
      throw new Error('Player is not initialized');
    }
    seekedPositionRef.current = 0;
    if (isInfiniteDuration) {
      // if duration is Infinite // for iOS timer
      const vodDuration = usePlayerStore.getState().playerDuration;
      setIsSeekbarSeekPending(true);
      if (isiOS()) {
        seekedPositionRef.current = 0; // no need to this.
        const videoElement = currentPlayer.getHTMLVideoElement();
        if (videoElement) {
          CONSOLE_LOG('[SEEK_REQUESTED_SECOND] :: data', time);
          videoElement.currentTime = time;
          if (!isRequested) {
            isSeekRequestedAgain_ios_mweb.current = true;
            isSeekRequestedAgainPosition_ios_mweb.current = time;
          }
          setTimeout(() => {
            CONSOLE_LOG('[SEEK_COMPLETED_SECOND] :: data', time);
            const isPlayerMuted = usePlayerStore.getState().isMuted;
            const currentPlayer = IvsPlayerRef.current;
            if (!isPlayerMuted && currentPlayer && currentPlayer.isMuted()) {
              // unmute player when If player got mute after seeking to position
              currentPlayer.setMuted(false);
            }
            setPosition(time);
            setIsSeekbarSeekPending(false);
          }, 10);
        }
        // for iOS mWeb, we need to seek using videoplayer element
        return;
      }
      seekedPositionRef.current = time; // for reference
      currentPlayer.seekTo(time - vodDuration);
      return;
    }
    setIsSeekbarSeekPending(true);
    currentPlayer.seekTo(time);
    CONSOLE_LOG('[SEEK_REQUESTED] :: data', time);
    return;
  };
  useEffect(() => {
    if (isInfiniteDuration && isPaused) {
      seekedPositionRef.current = position;
    }
    // position: Don't add this
  }, [isInfiniteDuration, isPaused]);
  useEffect(() => {
    if (!internalError) {
      return;
    }
    const currentPlayer = IvsPlayerRef.current;
    if (!currentPlayer) {
      return;
    }
    setIsBuffering(true);
    const errorCount = internalError?.count || 0;
    if (errorCount >= 10) {
      const timer = workerTimers.setTimeout(() => {
        setError(internalError);
        const currentPlayer = IvsPlayerRef.current;
        if (currentPlayer) {
          currentPlayer.pause();
        }
      }, 1000);
      return () => {
        workerTimers.clearTimeout(timer);
      };
    }
    const timer = workerTimers.setTimeout(() => {
      loadVideoPlayer();
      const currentPlayer = IvsPlayerRef.current;
      if (currentPlayer) {
        currentPlayer.play();
      }
    }, 1000);
    return () => {
      workerTimers.clearTimeout(timer);
    };
  }, [internalError]);

  // Effects
  useEffect(() => {
    if (!src) {
      return;
    }
    CONSOLE_LOG(`canInitializePlayer:`, isValidSourceForInitialization);
    if (!isValidSourceForInitialization || !window.IVSPlayer) {
      setIsPlayerInitialized(false);
      setIsInitialLoading(false);
      return;
    }
    const videoElement = (document.getElementById(isLivePlayer ? PLAYER_TYPE_ID.LIVE : PLAYER_TYPE_ID.VOD) as HTMLVideoElement);
    if (!videoElement) {
      // eslint-disable-next-line no-console
      console.error('Unable to get Video element for IVS player.');
      setIsPlayerInitialized(false);
      setIsInitialLoading(false);
      return;
    }
    const {
      isPlayerSupported
    } = AWSIVSPlayer;
    if (!isPlayerSupported) {
      // eslint-disable-next-line no-console
      console.error('The current browser does not support the Amazon IVS player.');
      setIsPlayerInitialized(false);
      setIsInitialLoading(false);
      return;
    }

    // Setting Values to Default Here
    seekedPositionRef.current = 0;
    setIsPlayerInitialized(false);
    setIsInitialLoading(true);
    setIsSeekbarSeekPending(false);
    setIsBuffering(false);
    setIsPaused(true);
    setIsReady(false);
    setHasEnded(false);
    setError(null);
    setInternalError(null);
    setIsPlaybackBlocked(false);
    IvsPlayerRef.current = IvsPlayerRef.current || (window.IVSPlayer.create() as IMediaPlayer);

    // // Test Code::: Only for stage2, localhost etc
    // window['__player_' + type] = IvsPlayerRef.current

    const currentPlayer = IvsPlayerRef.current;
    if (isIVS_LLStream) {
      currentPlayer.setRequestCredentials('omit');
    } else {
      currentPlayer.setRequestCredentials('include');
    }
    _setIsMuted(currentPlayer.isMuted());
    _setIsVolume(currentPlayer.getVolume());
    _setQualitites(currentPlayer.getQualities());
    _setQuality(currentPlayer.getQuality());
    _setPlaybackRate(currentPlayer.getPlaybackRate());
    const {
      IDLE,
      ENDED,
      PLAYING,
      READY,
      BUFFERING
    } = PlayerState;
    const {
      INITIALIZED,
      QUALITY_CHANGED,
      DURATION_CHANGED,
      VOLUME_CHANGED,
      MUTED_CHANGED,
      PLAYBACK_RATE_CHANGED,
      REBUFFERING,
      AUDIO_BLOCKED,
      PLAYBACK_BLOCKED,
      ERROR,
      TIME_UPDATE,
      // BUFFER_UPDATE,
      SEEK_COMPLETED,
      TEXT_CUE,
      TEXT_METADATA_CUE,
      NETWORK_UNAVAILABLE
    } = PlayerEventType;
    currentPlayer.attachHTMLVideoElement(videoElement);
    const onIdle = () => {
      onStateChangeRef.current(IDLE);
    };
    const onEnded = () => {
      onStateChangeRef.current(ENDED);
    };
    const onPlaying = () => {
      onStateChangeRef.current(PLAYING);
    };
    const onReady = () => {
      setIsInitialLoading(false);
      onStateChangeRef.current(READY);
    };
    const onBuffering = () => {
      onStateChangeRef.current(BUFFERING);
    };
    function onInitialized(data: any) {
      setIsPlayerInitialized(true);
      CONSOLE_LOG('[INITIALIZED] :: data', data);
    }
    function onQualityChanged(data: any) {
      CONSOLE_LOG('[QUALITY_CHANGED] :: data', data);
      _setQuality(data);
    }
    function onDurationChanged(currentDuration: number) {
      setDuration(currentDuration);
      setIsReady(true);
      CONSOLE_LOG('[DURATION_CHANGED] :: data', currentDuration);
    }
    function onVolumeChanged(volume: number) {
      volume = Math.round(volume * 10) / 10;
      _setIsVolume(volume);
      CONSOLE_LOG('[VOLUME_CHANGED] :: data', volume);
    }
    function onMutedChanged(data: any) {
      if (IvsPlayerRef.current) {
        const isMuted = IvsPlayerRef.current.isMuted();
        _setIsMuted(isMuted);
        CONSOLE_LOG('[MUTED_CHANGED] :: isMuted', isMuted);
        return;
      }
      CONSOLE_LOG('[MUTED_CHANGED] :: data', data);
    }
    function onPlaybackRateChanged(data: any) {
      CONSOLE_LOG('[PLAYBACK_RATE_CHANGED] :: data', data);
      _setPlaybackRate(data);
    }
    function onRebuffering(data: any) {
      CONSOLE_LOG('[REBUFFERING] :: data', data);
    }
    function onAudioBlocked(data: any) {
      _setIsMuted(true);
      CONSOLE_LOG('[AUDIO_BLOCKED] :: data', data);
    }
    function onPlaybackBlocked(data: any) {
      CONSOLE_LOG('[PLAYBACK_BLOCKED] :: data', data);
      onPlaybackBlockHandler(type);
      setIsPaused(true);
      setIsPlaybackBlocked(true);
    }
    function onError(error: IPlayerError) {
      const IsFatalError = checkIfFatalError(error);
      CONSOLE_LOG('[ERROR] :: data', error, IsFatalError);
      if (IsFatalError) {
        setError(error);
        setInternalError(null);
      } else {
        setInternalError(previousError => {
          if (!previousError) {
            return {
              ...error,
              count: 1
            };
          }
          const newErrorType = `${error.code}${error.type}`;
          const oldErrorType = `${previousError.code}${previousError.type}`;
          if (oldErrorType === newErrorType) {
            return {
              ...error,
              count: (previousError?.count || 0) + 1
            };
          } else {
            return {
              ...error,
              count: 1
            };
          }
        });
      }
    }
    function onTimeUpdate(currentPosition: number) {
      const currentPlayer = IvsPlayerRef.current;
      const playerState = currentPlayer?.getState() || PlayerState.PLAYING;
      const duration = currentPlayer?.getDuration() || 0;
      const isInfiniteDuration = !isFinite(duration);
      if (isInfiniteDuration && playerState !== PlayerState.PLAYING) {
        // Sometimes, it is getting fired post player is pause.. &
        // for infinite duration, It is giving position as 0.00s
        return;
      }
      if (seekedPositionRef.current > 0 && !isiOS()) {
        // for ios mWeb, currentPosition is actual position from rewind.m3u8 file
        // Therefor, No need to manage this
        currentPosition = currentPosition + seekedPositionRef.current;
      }
      setPosition(currentPosition);
      // CONSOLE_LOG('[TIME_UPDATE] :: data', currentPosition)
    }

    // function onBufferUpdate(data: any) {
    //   CONSOLE_LOG('[BUFFER_UPDATE] :: data', data)
    // }

    function onSeekCompleted(currentPosition: number) {
      CONSOLE_LOG('[SEEK_COMPLETED] :: cp, sp', currentPosition, seekedPositionRef.current);
      if (seekedPositionRef.current > 0) {
        currentPosition = seekedPositionRef.current;
      }
      const isPlayerMuted = usePlayerStore.getState().isMuted;
      const currentPlayer = IvsPlayerRef.current;
      if (!isPlayerMuted && currentPlayer && currentPlayer.isMuted()) {
        // unmute player when If player got mute after seeking to position
        currentPlayer.setMuted(false);
      }
      setPosition(currentPosition);
      setIsSeekbarSeekPending(false);
    }
    function onTextCue(data: any) {
      CONSOLE_LOG('[TEXT_CUE] :: data', data);
    }
    function onTextMetadataCue(data: any) {
      CONSOLE_LOG('[TEXT_METADATA_CUE] :: data', data);
    }
    function onNetworkUnavailable(data: any) {
      CONSOLE_LOG('[NETWORK_UNAVAILABLE] :: data', data);
    }
    currentPlayer.addEventListener(INITIALIZED, onInitialized);
    currentPlayer.addEventListener(QUALITY_CHANGED, onQualityChanged);
    currentPlayer.addEventListener(DURATION_CHANGED, onDurationChanged);
    currentPlayer.addEventListener(VOLUME_CHANGED, onVolumeChanged);
    currentPlayer.addEventListener(MUTED_CHANGED, onMutedChanged);
    currentPlayer.addEventListener(PLAYBACK_RATE_CHANGED, onPlaybackRateChanged);
    currentPlayer.addEventListener(REBUFFERING, onRebuffering);
    currentPlayer.addEventListener(AUDIO_BLOCKED, onAudioBlocked);
    currentPlayer.addEventListener(PLAYBACK_BLOCKED, onPlaybackBlocked);
    currentPlayer.addEventListener(ERROR, onError);
    currentPlayer.addEventListener(TIME_UPDATE, onTimeUpdate);
    // currentPlayer.addEventListener(BUFFER_UPDATE, onBufferUpdate)
    currentPlayer.addEventListener(SEEK_COMPLETED, onSeekCompleted);
    currentPlayer.addEventListener(TEXT_CUE, onTextCue);
    currentPlayer.addEventListener(TEXT_METADATA_CUE, onTextMetadataCue);
    currentPlayer.addEventListener(NETWORK_UNAVAILABLE, onNetworkUnavailable);
    currentPlayer.addEventListener(IDLE, onIdle);
    currentPlayer.addEventListener(ENDED, onEnded);
    currentPlayer.addEventListener(PLAYING, onPlaying);
    currentPlayer.addEventListener(READY, onReady);
    currentPlayer.addEventListener(BUFFERING, onBuffering);
    CONSOLE_LOG(`Player Initialized / Created`);
    return () => {
      setTimeToLoad(0);
      setIsSeekbarSeekPending(false);
      setIsPlayerInitialized(false);
      setIsInitialLoading(false);
      setIsBuffering(false);
      setIsPaused(true);
      setIsReady(false);
      setHasEnded(false);
      setError(null);
      setInternalError(null);
      currentPlayer.removeEventListener(INITIALIZED, onInitialized);
      currentPlayer.removeEventListener(QUALITY_CHANGED, onQualityChanged);
      currentPlayer.removeEventListener(DURATION_CHANGED, onDurationChanged);
      currentPlayer.removeEventListener(VOLUME_CHANGED, onVolumeChanged);
      currentPlayer.removeEventListener(MUTED_CHANGED, onMutedChanged);
      currentPlayer.removeEventListener(PLAYBACK_RATE_CHANGED, onPlaybackRateChanged);
      currentPlayer.removeEventListener(REBUFFERING, onRebuffering);
      currentPlayer.removeEventListener(AUDIO_BLOCKED, onAudioBlocked);
      currentPlayer.removeEventListener(PLAYBACK_BLOCKED, onPlaybackBlocked);
      currentPlayer.removeEventListener(ERROR, onError);
      currentPlayer.removeEventListener(TIME_UPDATE, onTimeUpdate);
      // currentPlayer.removeEventListener(BUFFER_UPDATE, onBufferUpdate)
      currentPlayer.removeEventListener(SEEK_COMPLETED, onSeekCompleted);
      currentPlayer.removeEventListener(TEXT_CUE, onTextCue);
      currentPlayer.removeEventListener(TEXT_METADATA_CUE, onTextMetadataCue);
      currentPlayer.removeEventListener(NETWORK_UNAVAILABLE, onNetworkUnavailable);
      currentPlayer.removeEventListener(IDLE, onIdle);
      currentPlayer.removeEventListener(ENDED, onEnded);
      currentPlayer.removeEventListener(PLAYING, onPlaying);
      currentPlayer.removeEventListener(READY, onReady);
      currentPlayer.removeEventListener(BUFFERING, onBuffering);
      currentPlayer.pause();
      currentPlayer.delete();
      IvsPlayerRef.current = null;
      CONSOLE_LOG(`Player Removed:`);
    };
  }, [isChannelLive, isValidSourceForInitialization]);
  const initializePlayer = () => {
    setCanInitializePlayer(true);
  };
  useEffect(() => {
    if (!isPlaybackBlocked) {
      return;
    }
    const timer = workerTimers.setTimeout(() => {
      // try to play one more time only,
      const currentPlayer = IvsPlayerRef.current;
      if (!currentPlayer) return;
      currentPlayer.play();
    }, 500);
    return () => {
      workerTimers.clearTimeout(timer);
    };
  }, [isPlaybackBlocked]);
  useEffect(() => {
    if (!src) {
      return;
    }
    const currentPlayer = IvsPlayerRef.current;
    CONSOLE_LOG(`useEffect ~ isPlayerInitialized | src | CMD`, isPlayerInitialized, !!src, canInitializePlayer);
    if (!canInitializePlayer || !currentPlayer || !isPlayerInitialized) {
      return;
    }
    p_start_loaded_on_ref.current = Date.now();
    p_end_time_ref.current = 0;
    setTimeToLoad(0);
    loadVideoPlayer();
    currentPlayer.setMuted(true);
  }, [isPlayerInitialized, src, canInitializePlayer]);
  return {
    initializePlayer,
    timeToLoad,
    type,
    play,
    pause,
    setMuted,
    setVolume,
    getPosition,
    setQuality,
    setPlaybackRate,
    setAutoQualityMode,
    seekTo,
    quality,
    qualitites,
    playbackRate,
    activeState,
    position,
    isMuted,
    volume,
    duration,
    isAutoQualityMode,
    isPlayerInitialized,
    isSeekbarSeekPending,
    isInitialLoading,
    isBuffering,
    isPaused,
    isReady,
    hasEnded,
    internalError,
    error,
    hasError
  };
};
export default usePlayer;
export type IPlayerResponse = ReturnType<typeof usePlayer>;