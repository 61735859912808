import { usePlayerStore } from '@components/VideoPlayer/store/usePlayerStore';
import { playerLogs } from '@components/VideoPlayer/Utils/helper';
import { STREAMER_TYPE, StreamInterface } from '@customTypes/index';
import { unicornEvents } from '@functions/stream';
import { isWebview } from '@layouts/core/Header/headerRightLogo';
import { Amplitude } from '@modules/analytics';
import { useAppStore } from '@stores/appStore';
import { useAuthStore } from '@stores/authStore';
import { useProfileStore } from '@stores/profileStore';
import { useStreamStore } from '@stores/streamStore';
import { fetchEventData, fetchStreamStatsEventData, fetchWatchIntervalEventData, onHandleVisitStreamToRemoveOlderData, resetEvent, resetStreamStatsEvent, resetWatchStremEvent, updateMetric } from '@stores/usePlayerEvents';
import { isProduction } from '@utils/platform';
import dayjs from 'dayjs';
import isMobile from 'is-mobile';

// import { getUtmCampaign } from '../../functions/utmCampaign'
import { logWatchVideoEvent } from './events';
import { getSourceEventProperties, getViewEventProperties } from './helpers';
const setUnicornSourceTrackingInfoWhenVisitEventFired = useStreamStore.getState().setUnicornSourceTrackingInfoWhenVisitEventFired;
const dLog = new playerLogs();
const IS_STAGING = process.env.IS_STAGING ? JSON.parse(process.env.IS_STAGING) : false;

// const utmParams = getUtmCampaign()
export const resettingCountMetrics = (streamUid: string) => {
  useStreamStore.getState().resetPlayerDescriptionExpandCount();
  resetEvent(streamUid);
};
export const unicornLogFun = (streamUid?: string) => {
  const {
    stream: c_stream,
    playerDescriptionExpandCount
  } = useStreamStore.getState();
  const {
    stream_info,
    total_watch_duration: _total_watch_duration,
    theatre_mode_watch_duration: _theatre_mode_watch_duration,
    fullscreen_mode_watch_duration: _fullscreen_mode_watch_duration,
    pip_mode_watch_duration: _pip_mode_watch_duration,
    theatre_mode_toggle_count,
    fullscreen_mode_toggle_count,
    pip_mode_toggle_count,
    stream_join_time,
    chat_section_collapsed_count,
    chat_section_reopen_count,
    player_seek_bar_count,
    buffered_time_in_seconds: _buffered_time_in_seconds,
    times_buffered
  } = fetchEventData(streamUid || '');
  const stream = stream_info || c_stream;
  const sourceTrackingInfo = useStreamStore.getState().unicornSourceTrackingInfoWhenVisitEventFired;
  const fullscreen_mode_watch_duration = Math.round(_fullscreen_mode_watch_duration);
  const total_watch_duration = Math.round(_total_watch_duration);
  const theatre_mode_watch_duration = Math.round(_theatre_mode_watch_duration);
  const pip_mode_watch_duration = Math.round(_pip_mode_watch_duration);
  const buffered_time_in_seconds = Math.round(_buffered_time_in_seconds);
  const {
    me
  } = useAuthStore.getState();
  const pageInfo = useAppStore.getState().pageInfo;

  // unicron sourceTrackingInfo when visit Event fire so we are sending correct info in watch_stream event also

  const sourceEventProperties = getSourceEventProperties(sourceTrackingInfo);
  if (stream?.uid) {
    if (me?.username !== stream?.streamer.username) {
      // Trigger event on stream exit or with watch_stream
      // To force there 3 events parallely
      setTimeout(() => logWatchStreamIntervalEvent({
        stream
      }), 0);
      setTimeout(() => logStreamStatsEvent({
        source: 'exit',
        stream
      }), 0);
      setTimeout(() => {
        logWatchVideoEvent({
          stream,
          duration: total_watch_duration,
          seekBarCount: player_seek_bar_count,
          buffered_time_in_seconds: buffered_time_in_seconds,
          times_buffered: times_buffered,
          gold_earned: 0,
          playerDescriptionExpandCount: playerDescriptionExpandCount,
          theatreModeUsed: theatre_mode_toggle_count > 0,
          theatreModeWatchCount: theatre_mode_watch_duration,
          fullscreenModeUsed: fullscreen_mode_toggle_count > 0,
          fullscreenModeWatchCount: fullscreen_mode_watch_duration,
          chatToggleCount: {
            chatCollapsed: chat_section_collapsed_count,
            chatReOpened: chat_section_reopen_count
          },
          streamJoinTime: stream_join_time,
          sourceEventProperties,
          stream_web_page_type: pageInfo.page,
          commonEvents: {
            player_full_screen_toggle_count: fullscreen_mode_toggle_count,
            player_theatre_mode_toggle_count: theatre_mode_toggle_count,
            non_pip_watch_duration: total_watch_duration - pip_mode_watch_duration,
            pip_watch_duration: pip_mode_watch_duration,
            miniplayer_toggle_count: pip_mode_toggle_count,
            player_pip_count: pip_mode_toggle_count,
            miniplayer_used: pip_mode_toggle_count > 0
          }
        });
        resettingCountMetrics(stream.uid);
      }, 0);
    } else {
      dLog.log(`Amplitude/Unicorn watch_* Event: Not Fired:`);
      resettingCountMetrics(stream.uid);
    }
  } else {
    dLog.log(`Amplitude/Unicorn watch_* Event: Not Fired:`);
  }
};
export const filterDesktopSpecificFields = (obj: any): any => {
  if (isMobile()) {
    /*
     * using Destructuring to remove properties from object
     */
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      pip_watch_duration,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      player_pip_count,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      miniplayer_toggle_count,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      miniplayer_used,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      player_pip_close_button_clicked,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      player_pip_expand,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      player_theatre_mode_toggle_count,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      theatre_mode_used,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      theatre_mode_watch_duration,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      times_chat_collapsed,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      times_chat_reopened,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      source_component_highlighted,
      ...newObj
    } = obj;
    return newObj;
  } else {
    /*
     * using Destructuring to remove properties from object
     */

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {
      player_description_expand_count,
      ...newObj
    } = obj;
    return newObj;
  }
};
export const unicornVisitLogFun = () => {
  const stream = useStreamStore.getState().stream;
  if (!stream?.uid) return;
  updateMetric(stream?.uid, 'stream_info', stream);
  onHandleVisitStreamToRemoveOlderData(stream?.uid);
  const eventProperties = getViewEventProperties(stream);
  const fingerprint = localStorage.getItem('fingerprint');
  const me = useAuthStore.getState().me;
  const wallet = useProfileStore.getState().wallet;
  const {
    stream_join_time_in_epoch
  } = fetchEventData(stream.uid);
  const unicornSourceTrackingInfo = useStreamStore.getState().unicornSourceTrackingInfo;
  const isModerator = useStreamStore.getState().isModerator;
  const web_page_type = useAppStore.getState().pageInfo.page;
  const {
    is_charging,
    manufacturer,
    network_speed_kbps,
    network_type,
    os_name,
    os_version,
    timezone_offset,
    screen,
    browser,
    browserVersion,
    browserMajorVersion,
    cookies
  } = useAppStore.getState().deviceInfo;
  const {
    botInfo
  } = useAppStore.getState();
  const isFollowingProperty = me?.user_uid && {
    is_followed: useStreamStore.getState().isFollowing
  };
  const sessionId = `${stream?.uid}-${stream_join_time_in_epoch}`;
  // setting unicron sourceTrackingInfo for watch_stream
  const sourceEventProperties = getSourceEventProperties(unicornSourceTrackingInfo);
  setUnicornSourceTrackingInfoWhenVisitEventFired(unicornSourceTrackingInfo);
  const value = {
    context: {
      device_id: fingerprint,
      env: IS_STAGING ? 'staging' : 'prod',
      gold_count: me?.username ? wallet.gold : undefined,
      is_charging: is_charging,
      logged_in: me?.username ? true : false,
      manufacturer: manufacturer,
      network_speed_kbps: network_speed_kbps,
      network_type: network_type,
      origin: 'client',
      os_name: os_name,
      os_version: os_version,
      browser_screen: screen,
      browser_name: browser,
      browser_version: browserVersion,
      browser_major_version: browserMajorVersion,
      browser_cookies_enable: cookies,
      platform: 7,
      streamer: me?.can_stream ? true : false,
      user_uid: me?.user_uid ? me.user_uid : undefined,
      username: me?.username ? me.username : undefined
    },
    events: [{
      event_name: stream?.status === 20 ? 'visit_stream' : 'visit_vod',
      props: {
        is_content_mature: stream?.has_mature_content ? true : false,
        bot_probable: botInfo?.bot,
        bot_kind: botInfo?.bot ? botInfo?.botKind : undefined,
        bk_device_id: fingerprint ? fingerprint : undefined,
        bk_user_id: me?.user_uid ? me.user_uid : undefined,
        category_id: stream?.game_uid,
        category_name: stream?.game_name,
        mod_view: isModerator !== 0 ? true : false,
        platform: 7,
        session_id: sessionId,
        streamer_id: stream?.streamer?.user_uid,
        streamer_name: stream?.streamer?.username,
        streamer_type: stream?.streamer?.user_type || 10,
        time_zone: timezone_offset ? timezone_offset : undefined,
        timestamp: Date.now(),
        stream_name: stream?.title,
        video_id: stream?.uid,
        video_tags: stream?.tags,
        video_view_count: stream?.viewersCurrent,
        streamer_category: stream?.streamer.user_type === STREAMER_TYPE.partner ? 'partner ' : stream?.streamer.user_type === STREAMER_TYPE.organic ? 'organic' : stream?.streamer.user_type === STREAMER_TYPE.admin ? 'admin' : stream?.streamer.user_type === STREAMER_TYPE.premium ? 'premium' : stream?.streamer.user_type === STREAMER_TYPE.viewer ? 'viewer' : 'viewer',
        ...sourceEventProperties,
        ...isFollowingProperty,
        source_component_highlighted: sourceEventProperties.source_component_highlighted === 'yes' ? true : false,
        source_sidenav: sourceEventProperties.source_sidenav === 'yes' ? true : false,
        source_view_all_in_source_component: sourceEventProperties.hasOwnProperty('source_view_all_in_source_component') ? sourceEventProperties?.source_view_all_in_source_component === 'yes' ? true : false : undefined,
        web_page_type: web_page_type,
        web_platform: 'loco.gg',
        web_device_type: isMobile() ? isWebview ? 'webview' : 'mobile_browser' : 'desktop'
        // share_platform: utmParams.share_platform,
        // share_screen: utmParams.share_screen,
        // share_userid: utmParams.share_userid,
        // is_share: utmParams.share_platform ? true : false,
      }
    }]
  };

  if (me?.username !== stream?.streamer.username) {
    dLog.log(`Amplitude visit_* Event: Fired:`, Date.now());
    if (stream?.status === 20) {
      Amplitude.logEvent('visit_stream', {
        ...eventProperties,
        ...sourceEventProperties,
        ...isFollowingProperty,
        web_page_type: web_page_type,
        web_device_type: isMobile() ? isWebview ? 'webview' : 'mobile_browser' : 'desktop',
        session_id: sessionId,
        stream_name: stream?.title,
        mod_view: isModerator !== 0 ? true : false,
        web_platform: undefined
        // share_platform: utmParams.share_platform,
        // share_screen: utmParams.share_screen,
        // share_userid: utmParams.share_userid,
        // is_share: utmParams.share_platform ? true : false,
      });
    } else {
      Amplitude.logEvent('visit_vod', {
        ...eventProperties,
        ...sourceEventProperties,
        ...isFollowingProperty,
        web_page_type: web_page_type,
        web_device_type: isMobile() ? isWebview ? 'webview' : 'mobile_browser' : 'desktop',
        session_id: sessionId,
        stream_name: stream?.title,
        vod_length: stream!.duration,
        vod_type: stream?.is_uploaded ? 'uploaded' : 'converted_from_stream',
        web_platform: undefined
        // share_platform: utmParams.share_platform,
        // share_screen: utmParams.share_screen,
        // share_userid: utmParams.share_userid,
        // is_share: utmParams.share_platform ? true : false,
      });
    }

    if (isProduction()) {
      dLog.log(`Unicorn visit_* Event: Fired: YYYYYYY`, Date.now());
      unicornEvents(value);
    } else {
      dLog.log(`Unicorn visit_* Event: Not Fired:`);
    }
  } else {
    dLog.log(`Amplitude/Unicorn visit_* Event: Not Fired:`);
  }
};
interface UnicornGiftSendFunProps {
  card_impressions: number;
  gift_sent: string;
  gift_id: string;
  gift_currency_type: string;
  gift_currency_id: number;
  gift_time: number;
  gift_value: number;
  gift_animated: boolean;
  gift_tab: string;
  gift_h_index: number;
  gift_v_index: number;
  is_vip: boolean;
  gift_type: string;
}
export const unicornGiftSendFun = (gift: UnicornGiftSendFunProps) => {
  const stream = useStreamStore.getState().stream;
  if (!stream) return;
  const fingerprint = localStorage.getItem('fingerprint');
  const me = useAuthStore.getState().me;
  const {
    stream_join_time_in_epoch
  } = fetchEventData(stream.uid);
  const sessionId = `${stream?.uid}-${stream_join_time_in_epoch}`;
  const wallet = useProfileStore.getState().wallet;
  const isFollowingProperty = me?.user_uid && {
    is_followed: useStreamStore.getState().isFollowing
  };
  const sourceEventProperties = getSourceEventProperties(useAppStore.getState().sourceTrackingInfo);
  const botInfo = useAppStore.getState().botInfo;
  const web_page_type = useAppStore.getState().pageInfo.page;
  const {
    is_charging,
    manufacturer,
    network_speed_kbps,
    network_type,
    os_name,
    os_version,
    timezone_offset,
    screen,
    browser,
    browserVersion,
    browserMajorVersion,
    cookies
  } = useAppStore.getState().deviceInfo;
  const value = {
    context: {
      device_id: fingerprint,
      env: IS_STAGING ? 'staging' : 'prod',
      gold_count: me?.username ? wallet.gold : undefined,
      is_charging: is_charging,
      logged_in: me?.username ? true : false,
      manufacturer: manufacturer,
      network_speed_kbps: network_speed_kbps,
      network_type: network_type,
      origin: 'client',
      os_name: os_name,
      os_version: os_version,
      browser_screen: screen,
      browser_name: browser,
      browser_version: browserVersion,
      browser_major_version: browserMajorVersion,
      browser_cookies_enable: cookies,
      platform: 7,
      streamer: me?.can_stream ? true : false,
      user_uid: me?.user_uid ? me.user_uid : undefined,
      username: me?.username ? me.username : undefined
    },
    events: [{
      event_name: 'gift_send',
      props: {
        bot_probable: botInfo?.bot,
        is_content_mature: stream?.has_mature_content ? true : false,
        bot_kind: botInfo?.bot ? botInfo?.botKind : undefined,
        bk_device_id: fingerprint ? fingerprint : undefined,
        bk_user_id: me?.user_uid ? me.user_uid : undefined,
        video_id: stream?.uid,
        streamer_name: stream?.streamer.username,
        streamer_id: stream?.streamer.user_uid,
        video_tags: stream?.tags,
        category_name: stream?.game_name,
        category_id: stream?.game_uid,
        platform: 7,
        stream_name: stream?.title,
        stream_url: stream?.VODUrlV2 || stream?.VODUrl,
        streamer_category: stream?.streamer.user_type === STREAMER_TYPE.partner ? 'partner ' : stream?.streamer.user_type === STREAMER_TYPE.organic ? 'organic' : stream?.streamer.user_type === STREAMER_TYPE.admin ? 'admin' : stream?.streamer.user_type === STREAMER_TYPE.premium ? 'premium' : stream?.streamer.user_type === STREAMER_TYPE.viewer ? 'viewer' : 'viewer',
        streamer_type: stream?.streamer.user_type,
        thumbnail_url: stream?.thumbnail_url_small,
        time_zone: timezone_offset ? timezone_offset : undefined,
        timestamp: Date.now(),
        session_id: sessionId,
        ...sourceEventProperties,
        ...gift,
        ...isFollowingProperty,
        source_component_highlighted: sourceEventProperties.source_component_highlighted === 'yes' ? true : false,
        source_sidenav: sourceEventProperties.source_sidenav === 'yes' ? true : false,
        source_view_all_in_source_component: sourceEventProperties.hasOwnProperty('source_view_all_in_source_component') ? sourceEventProperties?.source_view_all_in_source_component === 'yes' ? true : false : undefined,
        web_page_type: web_page_type,
        web_platform: 'loco.gg',
        web_device_type: isMobile() ? isWebview ? 'webview' : 'mobile_browser' : 'desktop'
        // share_platform: utmParams.share_platform,
        // share_screen: utmParams.share_screen,
        // share_userid: utmParams.share_userid,
        // is_share: utmParams.share_platform ? true : false,
      }
    }]
  };

  Amplitude.logEvent('gift_send', {
    bot_probable: botInfo?.bot,
    is_content_mature: stream?.has_mature_content ? true : false,
    bot_kind: botInfo?.bot ? botInfo?.botKind : undefined,
    bk_device_id: fingerprint ? fingerprint : undefined,
    bk_user_id: me?.user_uid ? me.user_uid : undefined,
    video_id: stream?.uid,
    streamer_name: stream?.streamer.username,
    streamer_id: stream?.streamer.user_uid,
    video_tags: stream?.tags,
    category_name: stream?.game_name,
    category_id: stream?.game_uid,
    platform: 7,
    stream_name: stream?.title,
    stream_url: stream?.VODUrlV2 || stream?.VODUrl,
    streamer_category: stream?.streamer.user_type === STREAMER_TYPE.partner ? 'partner ' : stream?.streamer.user_type === STREAMER_TYPE.organic ? 'organic' : stream?.streamer.user_type === STREAMER_TYPE.admin ? 'admin' : stream?.streamer.user_type === STREAMER_TYPE.premium ? 'premium' : stream?.streamer.user_type === STREAMER_TYPE.viewer ? 'viewer' : 'viewer',
    streamer_type: stream?.streamer.user_type,
    thumbnail_url: stream?.thumbnail_url_small,
    time_zone: timezone_offset ? timezone_offset : undefined,
    timestamp: Date.now(),
    // session_id: sessionId,
    ...sourceEventProperties,
    ...isFollowingProperty,
    ...gift,
    web_page_type: web_page_type,
    web_device_type: isMobile() ? isWebview ? 'webview' : 'mobile_browser' : 'desktop'
    // share_platform: utmParams.share_platform,
    // share_screen: utmParams.share_screen,
    // share_userid: utmParams.share_userid,
    // is_share: utmParams.share_platform ? true : false,
  });

  if (me?.username !== stream?.streamer.username) {
    if (isProduction()) {
      unicornEvents(value);
    }
  }
};
type UnicornBannerAdsProps = {
  ad_type: string;
  v_position?: number;
};
export const unicornBannerAds = (adProps: UnicornBannerAdsProps) => {
  const stream = useStreamStore.getState().stream;
  if (!stream) return;
  const fingerprint = localStorage.getItem('fingerprint');
  const me = useAuthStore.getState().me;
  const wallet = useProfileStore.getState().wallet;
  const {
    stream_join_time_in_epoch
  } = fetchEventData(stream.uid);
  const unicornSourceTrackingInfo = useStreamStore.getState().unicornSourceTrackingInfo;
  setUnicornSourceTrackingInfoWhenVisitEventFired(unicornSourceTrackingInfo);
  const web_page_type = useAppStore.getState().pageInfo.page;
  const sourceEventProperties = getSourceEventProperties(unicornSourceTrackingInfo);
  const sessionId = `${stream?.uid}-${stream_join_time_in_epoch}`;
  const {
    is_charging,
    manufacturer,
    network_speed_kbps,
    network_type,
    os_name,
    os_version,
    timezone_offset,
    screen,
    browser,
    browserVersion,
    browserMajorVersion,
    cookies
  } = useAppStore.getState().deviceInfo;
  const botInfo = useAppStore.getState().botInfo;
  const value = {
    context: {
      device_id: fingerprint,
      env: IS_STAGING ? 'staging' : 'prod',
      gold_count: me?.username ? wallet.gold : undefined,
      is_charging: is_charging,
      logged_in: me?.username ? true : false,
      manufacturer: manufacturer,
      network_speed_kbps: network_speed_kbps,
      network_type: network_type,
      origin: 'client',
      os_name: os_name,
      os_version: os_version,
      browser_screen: screen,
      browser_name: browser,
      browser_version: browserVersion,
      browser_major_version: browserMajorVersion,
      browser_cookies_enable: cookies,
      platform: 7,
      streamer: me?.can_stream ? true : false,
      user_uid: me?.user_uid ? me.user_uid : undefined,
      username: me?.username ? me.username : undefined
    },
    events: [{
      event_name: 'impression_ad',
      props: {
        bot_probable: botInfo?.bot,
        bot_kind: botInfo?.bot ? botInfo?.botKind : undefined,
        bk_device_id: fingerprint ? fingerprint : undefined,
        bk_user_id: me?.user_uid ? me.user_uid : undefined,
        video_id: stream?.uid,
        streamer_name: stream?.streamer.username,
        streamer_id: stream?.streamer.user_uid,
        video_tags: stream?.tags,
        category_name: stream?.game_name,
        category_id: stream?.game_uid,
        video_type: stream?.status === 20 ? 'live' : 'VOD',
        platform: 7,
        stream_url: stream?.VODUrlV2,
        streamer_category: stream?.streamer.user_type === STREAMER_TYPE.partner ? 'partner ' : stream?.streamer.user_type === STREAMER_TYPE.organic ? 'organic' : stream?.streamer.user_type === STREAMER_TYPE.admin ? 'admin' : stream?.streamer.user_type === STREAMER_TYPE.premium ? 'premium' : stream?.streamer.user_type === STREAMER_TYPE.viewer ? 'viewer' : 'viewer',
        streamer_type: stream?.streamer.user_type,
        thumbnail_url: stream?.thumbnail_url_small,
        time_zone: timezone_offset ? timezone_offset : undefined,
        timestamp: Date.now(),
        session_id: sessionId,
        ...sourceEventProperties,
        source_component_highlighted: sourceEventProperties.source_component_highlighted === 'yes' ? true : false,
        source_sidenav: sourceEventProperties.source_sidenav === 'yes' ? true : false,
        source_view_all_in_source_component: sourceEventProperties.hasOwnProperty('source_view_all_in_source_component') ? sourceEventProperties?.source_view_all_in_source_component === 'yes' ? true : false : undefined,
        ...adProps,
        web_page_type: web_page_type,
        web_platform: 'loco.gg',
        web_device_type: isMobile() ? isWebview ? 'webview' : 'mobile_browser' : 'desktop'
      }
    }]
  };
  if (me?.username !== stream?.streamer.username) {
    if (isProduction()) {
      unicornEvents(value);
    }
  }
};
export const logStreamerErrorEvent = (params: {
  is_startup?: boolean;
  error_code?: number | string;
  error_message?: string;
  error_type?: string;
  player_type?: string;
  is_fatal_error?: boolean;
}) => {
  const {
    stream
  } = useStreamStore.getState();
  if (!stream) return;
  const {
    is_startup,
    error_code,
    error_message,
    error_type,
    player_type,
    is_fatal_error
  } = params;
  const sourceTrackingInfo = useStreamStore.getState().unicornSourceTrackingInfoWhenVisitEventFired;
  const sourceEventProperties = getSourceEventProperties(sourceTrackingInfo);
  const botInfo = useAppStore.getState().botInfo;
  const fingerprint = localStorage.getItem('fingerprint');
  const me = useAuthStore.getState().me;
  const wallet = useProfileStore.getState().wallet;
  const web_page_type = useAppStore.getState().pageInfo.page;
  const {
    is_charging,
    manufacturer,
    network_speed_kbps,
    network_type,
    os_name,
    os_version,
    timezone_offset,
    screen,
    browser,
    browserVersion,
    browserMajorVersion,
    cookies
  } = useAppStore.getState().deviceInfo;
  const {
    stream_join_time_in_epoch
  } = fetchEventData(stream.uid);
  const common_events: any = {
    is_startup,
    error_code,
    error_message: error_message || 'Somehting went wrong',
    error_type,
    player_type,
    is_fatal_error,
    has_internet_connection: undefined
  };
  if (typeof window !== 'undefined') {
    common_events['has_internet_connection'] = window.navigator.onLine;
  }
  const sessionId = `${stream?.uid}-${stream_join_time_in_epoch}`;
  const value = {
    context: {
      device_id: fingerprint,
      env: IS_STAGING ? 'staging' : 'prod',
      gold_count: me?.username ? wallet.gold : undefined,
      is_charging: is_charging,
      logged_in: me?.username ? true : false,
      manufacturer: manufacturer,
      network_speed_kbps: network_speed_kbps,
      network_type: network_type,
      origin: 'client',
      os_name: os_name,
      os_version: os_version,
      browser_screen: screen,
      browser_name: browser,
      browser_version: browserVersion,
      browser_major_version: browserMajorVersion,
      browser_cookies_enable: cookies,
      platform: 7,
      streamer: me?.can_stream ? true : false,
      user_uid: me?.user_uid ? me.user_uid : undefined,
      username: me?.username ? me.username : undefined
    },
    events: [{
      event_name: 'stream_playback_error',
      props: {
        bot_probable: botInfo?.bot,
        session_id: sessionId,
        bot_kind: botInfo?.bot ? botInfo?.botKind : undefined,
        bk_device_id: fingerprint ? fingerprint : undefined,
        bk_user_id: me?.user_uid ? me.user_uid : undefined,
        video_id: stream?.uid,
        streamer_name: stream?.streamer.username,
        streamer_id: stream?.streamer.user_uid,
        video_tags: stream?.tags,
        category_name: stream?.game_name,
        category_id: stream?.game_uid,
        platform: 7,
        stream_url: stream?.VODUrlV2 || stream?.VODUrl,
        stream_name: stream?.title,
        streamer_category: stream?.streamer.user_type === STREAMER_TYPE.partner ? 'partner ' : stream?.streamer.user_type === STREAMER_TYPE.organic ? 'organic' : stream?.streamer.user_type === STREAMER_TYPE.admin ? 'admin' : stream?.streamer.user_type === STREAMER_TYPE.premium ? 'premium' : stream?.streamer.user_type === STREAMER_TYPE.viewer ? 'viewer' : 'viewer',
        streamer_type: stream?.streamer.user_type,
        thumbnail_url: stream?.thumbnail_url_small,
        time_zone: timezone_offset ? timezone_offset : undefined,
        timestamp: Date.now(),
        ...sourceEventProperties,
        ...common_events,
        source_component_highlighted: sourceEventProperties.source_component_highlighted === 'yes' ? true : false,
        source_sidenav: sourceEventProperties.source_sidenav === 'yes' ? true : false,
        source_view_all_in_source_component: sourceEventProperties.hasOwnProperty('source_view_all_in_source_component') ? sourceEventProperties?.source_view_all_in_source_component === 'yes' ? true : false : undefined,
        web_page_type: web_page_type,
        web_platform: 'loco.gg',
        web_device_type: isMobile() ? isWebview ? 'webview' : 'mobile_browser' : 'desktop'
      }
    }]
  };
  if (isProduction()) {
    unicornEvents(value);
  }
};
const commonStreamRelatedProperties = ({
  stream
}: {
  stream: StreamInterface;
}) => {
  const {
    isFollowing
  } = useStreamStore.getState();
  const {
    stream_join_time_in_epoch,
    stream_join_time
  } = fetchEventData(stream.uid);
  const {
    me
  } = useAuthStore.getState();
  const {
    vipType: userVipType
  } = useProfileStore.getState();
  const {
    timezone_offset
  } = useAppStore.getState()?.deviceInfo;
  const sessionId = `${stream?.uid}-${stream_join_time_in_epoch}`;
  const isFollowingProperty = me?.user_uid ? !!isFollowing : undefined;
  const IS_CHAT_REPLAY_AVAILABLE = stream && stream.status === 30 && stream.started_at > 1645209000000;
  const vodSpecificProperities = stream?.status !== 20 ? {
    is_replay_chat_enabled: IS_CHAT_REPLAY_AVAILABLE ? true : false,
    video_upload_date: dayjs(stream?.updated_at).format('DD MM YYYY'),
    vod_length: stream?.duration,
    vod_type: stream?.is_uploaded ? 'uploaded' : 'converted_from_stream'
  } : {
    stream_join_time: stream_join_time
  };
  return {
    ...vodSpecificProperities,
    session_id: sessionId,
    web_device_type: isMobile() ? isWebview ? 'webview' : 'mobile_browser' : 'desktop',
    web_platform: 'loco.gg',
    time_zone: timezone_offset ? timezone_offset : undefined,
    timestamp: Date.now(),
    is_followed: isFollowingProperty,
    is_vip: userVipType || 'non-vip',
    is_streamer_vip: stream?.is_vip ? true : false,
    category_id: stream?.game_uid,
    category_name: stream?.game_name,
    stream_status: stream?.status,
    stream_name: stream?.title,
    streamer_id: stream?.streamer.user_uid,
    stream_url: stream?.VODUrlV2 || stream?.VODUrl,
    streamer_category: stream?.streamer.user_type === STREAMER_TYPE.partner ? 'partner ' : stream?.streamer.user_type === STREAMER_TYPE.organic ? 'organic' : stream?.streamer.user_type === STREAMER_TYPE.admin ? 'admin' : stream?.streamer.user_type === STREAMER_TYPE.premium ? 'premium' : stream?.streamer.user_type === STREAMER_TYPE.viewer ? 'viewer' : 'viewer',
    streamer_type: stream?.streamer.user_type,
    streamer_name: stream?.streamer.username,
    thumbnail_url: stream?.thumbnail_url_small,
    thumbnail: stream?.thumbnail_url_small,
    video_id: stream?.uid,
    video_tags: stream?.tags,
    video_upload_date: stream?.created_at,
    video_view_count: stream?.viewersCurrent,
    video_type: stream?.status === 20 ? 'live' : 'VOD'
  };
};
export const logWatchStreamIntervalEvent = ({
  stream
}: {
  stream: StreamInterface;
}) => {
  if (!stream) return;
  const {
    is_charging,
    manufacturer,
    network_speed_kbps,
    network_type,
    os_name,
    os_version,
    screen,
    browser,
    browserVersion,
    browserMajorVersion,
    cookies
  } = useAppStore.getState().deviceInfo;
  const {
    initial_bitrate,
    manual_bitrate_change
  } = fetchEventData(stream.uid);

  // unicron sourceTrackingInfo when visit Event fire so we are sending correct info in watch_stream event also
  const sourceTrackingInfo = useStreamStore.getState().unicornSourceTrackingInfoWhenVisitEventFired;
  const {
    isPlaying: player_is_playing
  } = useStreamStore.getState();
  const {
    total_watch_duration: _total_watch_duration,
    theatre_mode_watch_duration: _theatre_mode_watch_duration,
    fullscreen_mode_watch_duration: _fullscreen_mode_watch_duration,
    pip_mode_watch_duration: _pip_mode_watch_duration,
    buffered_time_in_seconds: _buffered_time_in_seconds,
    mute_duration: _mute_duration,
    mute_toggle_count,
    player_play_pause_toggle_count,
    theatre_mode_toggle_count,
    fullscreen_mode_toggle_count,
    pip_mode_toggle_count,
    chat_section_collapsed_count,
    chat_section_reopen_count,
    player_seek_bar_count,
    times_buffered,
    player_keyboard_seek_count
  } = fetchWatchIntervalEventData(stream.uid);
  const {
    me
  } = useAuthStore.getState();
  const {
    wallet
  } = useProfileStore.getState();
  const fingerprint = localStorage.getItem('fingerprint');
  const mute_duration = Math.round(_mute_duration);
  const total_watch_duration = Math.round(_total_watch_duration);
  const theatre_mode_watch_duration = Math.round(_theatre_mode_watch_duration);
  const fullscreen_mode_watch_duration = Math.round(_fullscreen_mode_watch_duration);
  const pip_mode_watch_duration = Math.round(_pip_mode_watch_duration);
  const buffered_time_in_seconds = Math.round(_buffered_time_in_seconds);
  const sourceEventProperties = getSourceEventProperties(sourceTrackingInfo);
  const last_manual_bitrate = manual_bitrate_change[manual_bitrate_change.length - 1] || initial_bitrate;
  const watchIntervalData = {
    ...sourceEventProperties,
    // Changing some source properties to boolean
    source_component_highlighted: sourceEventProperties.source_component_highlighted === 'yes' ? true : false,
    source_sidenav: sourceEventProperties.source_sidenav === 'yes' ? true : false,
    source_view_all_in_source_component: sourceEventProperties.hasOwnProperty('source_view_all_in_source_component') ? sourceEventProperties?.source_view_all_in_source_component === 'yes' ? true : false : undefined,
    ...commonStreamRelatedProperties({
      stream
    }),
    mute_duration,
    mute_toggle_count,
    theatre_mode_watch_duration,
    fullscreen_mode_watch_duration,
    pip_mode_watch_duration,
    theatre_mode_toggle_count,
    fullscreen_mode_toggle_count,
    pip_mode_toggle_count,
    chat_section_collapsed_count,
    chat_section_reopen_count,
    bitrate: last_manual_bitrate.toLowerCase() || 'auto',
    buffered_time_in_seconds,
    player_is_playing,
    gold_earned: 0,
    non_pip_watch_duration: total_watch_duration - pip_mode_watch_duration,
    pip_watch_duration: pip_mode_watch_duration,
    platform: 7,
    player_full_screen_toggle_count: fullscreen_mode_toggle_count,
    player_play_pause_toggle_count,
    player_seek_count: player_seek_bar_count + player_keyboard_seek_count,
    times_buffered,
    video_watch_duration: total_watch_duration
  };
  const value = {
    context: {
      device_id: fingerprint,
      env: IS_STAGING ? 'staging' : 'prod',
      gold_count: me?.username ? wallet.gold : undefined,
      is_charging: is_charging,
      logged_in: me?.username ? true : false,
      manufacturer: manufacturer,
      network_speed_kbps: network_speed_kbps,
      network_type: network_type,
      origin: 'client',
      os_name: os_name,
      os_version: os_version,
      browser_screen: screen,
      browser_name: browser,
      browser_version: browserVersion,
      browser_major_version: browserMajorVersion,
      browser_cookies_enable: cookies,
      platform: 7,
      streamer: me?.can_stream ? true : false,
      user_uid: me?.user_uid ? me.user_uid : undefined,
      username: me?.username ? me.username : undefined
    },
    events: [{
      event_name: 'watch_stream_interval',
      props: watchIntervalData
    }]
  };
  if (isProduction()) {
    unicornEvents(value);
    dLog.log(`Unicorn watch_stream_interval Event: Fired:`, Date.now(), value);
  } else {
    dLog.log(`Unicorn watch_stream_interval Event: Not Fired:`);
  }
  resetWatchStremEvent(stream.uid);
};
export const logStreamStatsEvent = ({
  stream,
  source
}: {
  stream: StreamInterface;
  source: 'exit' | 'interval'
  // | 'discontinuity'
  | 'bitrange_change' | 'autobitrate_change';
}) => {
  if (!stream) return;
  const {
    is_charging,
    manufacturer,
    network_speed_kbps,
    network_type,
    os_name,
    os_version,
    screen,
    browser,
    browserVersion,
    browserMajorVersion,
    cookies
  } = useAppStore.getState().deviceInfo;
  const {
    me
  } = useAuthStore.getState();
  const {
    initial_bitrate,
    manual_bitrate_change,
    web_perf_playback_api_time_ms,
    web_perf_render_ui_time_ms,
    web_perf_stream_start_time_ms
  } = fetchEventData(stream.uid);
  // unicron sourceTrackingInfo when visit Event fire so we are sending correct info in watch_stream event also
  const sourceTrackingInfo = useStreamStore.getState().unicornSourceTrackingInfoWhenVisitEventFired;
  const {
    //  playerDescriptionExpandCount,
    isPlaying: player_is_playing
  } = useStreamStore.getState();
  const {
    total_watch_duration: _total_watch_duration,
    buffered_time_in_seconds: _buffered_time_in_seconds,
    theatre_mode_watch_duration: _theatre_mode_watch_duration,
    fullscreen_mode_watch_duration: _fullscreen_mode_watch_duration,
    pip_mode_watch_duration: _pip_mode_watch_duration,
    mute_duration: _mute_duration,
    mute_toggle_count,
    theatre_mode_toggle_count,
    fullscreen_mode_toggle_count,
    pip_mode_toggle_count,
    chat_section_collapsed_count,
    chat_section_reopen_count,
    player_seek_bar_count,
    times_buffered,
    player_keyboard_seek_count
  } = fetchStreamStatsEventData(stream.uid);
  const {
    isPortalOpen,
    playerPosition,
    playerStateLogs: _playerStateLogs,
    playerState,
    resetPlayerLogs
  } = usePlayerStore.getState();
  const {
    wallet
  } = useProfileStore.getState();
  const fingerprint = localStorage.getItem('fingerprint');
  const playerStateLogs = _playerStateLogs.join(',');
  const total_watch_duration = Math.round(_total_watch_duration);
  const theatre_mode_watch_duration = Math.round(_theatre_mode_watch_duration);
  const fullscreen_mode_watch_duration = Math.round(_fullscreen_mode_watch_duration);
  const pip_mode_watch_duration = Math.round(_pip_mode_watch_duration);
  const buffered_time_in_seconds = Math.round(_buffered_time_in_seconds);
  const mute_duration = Math.round(_mute_duration);
  const sourceEventProperties = getSourceEventProperties(sourceTrackingInfo);
  const last_manual_bitrate = manual_bitrate_change[manual_bitrate_change.length - 1] || initial_bitrate;
  const streamStatsEventProps = {
    ...sourceEventProperties,
    // Changing some source properties to boolean
    source_component_highlighted: sourceEventProperties.source_component_highlighted === 'yes' ? true : false,
    source_sidenav: sourceEventProperties.source_sidenav === 'yes' ? true : false,
    source_view_all_in_source_component: sourceEventProperties.hasOwnProperty('source_view_all_in_source_component') ? sourceEventProperties?.source_view_all_in_source_component === 'yes' ? true : false : undefined,
    ...commonStreamRelatedProperties({
      stream
    }),
    bitrate: last_manual_bitrate.toLowerCase() || 'auto',
    mute_duration,
    mute_toggle_count,
    theatre_mode_watch_duration,
    fullscreen_mode_watch_duration,
    pip_mode_watch_duration,
    theatre_mode_toggle_count,
    fullscreen_mode_toggle_count,
    pip_mode_toggle_count,
    chat_section_collapsed_count,
    chat_section_reopen_count,
    is_in_pip_mode: isPortalOpen,
    source: source,
    buffered_time_in_seconds,
    web_perf_playback_api_time_ms,
    web_perf_render_ui_time_ms,
    web_perf_stream_start_time_ms,
    web_perf_total_time_ms: web_perf_playback_api_time_ms + web_perf_render_ui_time_ms + web_perf_stream_start_time_ms,
    platform: 7,
    player_is_playing,
    player_playback_state: playerState.toLowerCase(),
    player_seek_bar_count,
    player_keyboard_seek_count,
    player_seek_count: player_seek_bar_count + player_keyboard_seek_count,
    player_seek_logs: '',
    // on web, this only has [SEEK, SEEK, SEEK] therefore not adding it.
    times_buffered,
    user_seek_count: player_seek_bar_count + player_keyboard_seek_count,
    video_watch_duration: total_watch_duration,
    player_state_logs: playerStateLogs.toLowerCase(),
    player_stats_current_position: playerPosition,
    player_stats_selected_resolution: last_manual_bitrate.toLowerCase() || 'auto',
    video_quality: last_manual_bitrate.toLowerCase() || 'auto',
    player_stats_total_buffered_duration: buffered_time_in_seconds
  };
  const value = {
    context: {
      device_id: fingerprint,
      env: IS_STAGING ? 'staging' : 'prod',
      gold_count: me?.username ? wallet.gold : undefined,
      is_charging: is_charging,
      logged_in: me?.username ? true : false,
      manufacturer: manufacturer,
      network_speed_kbps: network_speed_kbps,
      network_type: network_type,
      origin: 'client',
      os_name: os_name,
      os_version: os_version,
      browser_screen: screen,
      browser_name: browser,
      browser_version: browserVersion,
      browser_major_version: browserMajorVersion,
      browser_cookies_enable: cookies,
      platform: 7,
      streamer: me?.can_stream ? true : false,
      user_uid: me?.user_uid ? me.user_uid : undefined,
      username: me?.username ? me.username : undefined
    },
    events: [{
      event_name: 'stream_player_stats',
      props: streamStatsEventProps
    }]
  };
  if (isProduction()) {
    unicornEvents(value);
    dLog.log(`Unicorn stream_player_stats Event: Fired:`, Date.now(), value);
  } else {
    dLog.log(`Unicorn stream_player_stats Event: Not Fired:`);
  }
  resetPlayerLogs(); // reset it to 0
  resetStreamStatsEvent(stream.uid);
};