function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import TrickPlay, { useTrickPlayStore } from '@components/TrickPlay';
import { HOVER_TIME_MS_ID, PLAYER_CONTROLS_SEEKBAR_WRAPPER_ID } from '@components/VideoPlayer/constants';
import { useControlDisplayStore } from '@components/VideoPlayer/store/useControlDisplayStore';
import { usePlayerStore } from '@components/VideoPlayer/store/usePlayerStore';
import usePlayerContext from '@components/VideoPlayer/store/WithContextProvider';
import { useStreamStore } from '@stores/streamStore';
import { increaseCount } from '@stores/usePlayerEvents';
import React, { useEffect } from 'react';
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const onHandleTrickPlayProgressPosition = ({
  cursiorPosition,
  seekBarWidth,
  screen_isDesktop
}: {
  cursiorPosition: number;
  seekBarWidth: number;
  screen_isDesktop: boolean;
}) => {
  const isFullScreen = useStreamStore.getState().isFullScreen;
  const cHoverSeekbarTime = document.getElementById(HOVER_TIME_MS_ID);
  // This particular section if for to adjust trick player on UI
  if (cHoverSeekbarTime) {
    const hoverSeekbarStyle = getComputedStyle(cHoverSeekbarTime);
    const leftMargin = parseFloat(hoverSeekbarStyle.width) / 2;
    const width_margin_to_check = leftMargin + (!screen_isDesktop ? -14 : isFullScreen ? 92 : 14); // 14px.. padding on both side
    if (cursiorPosition < width_margin_to_check) {
      const delta = cursiorPosition;
      // // if seek in towards 0 || starting position
      cHoverSeekbarTime.style.left = cursiorPosition - leftMargin + (width_margin_to_check - delta) + 'px';
    } else if (seekBarWidth - cursiorPosition < width_margin_to_check) {
      const delta = seekBarWidth - cursiorPosition;
      // // if seek in towards end || ending position
      cHoverSeekbarTime.style.left = cursiorPosition - leftMargin - (width_margin_to_check - delta) + 'px';
    } else {
      cHoverSeekbarTime.style.left = cursiorPosition - leftMargin + 'px';
    }
    cHoverSeekbarTime.style.display = 'flex';
    setTrickPlayIsVisible(true);
  }
};
const {
  setIsVisible: setTrickPlayIsVisible,
  setCurrentDuration: setTrickPlayDuration
} = useTrickPlayStore.getState();
const {
  setIsSeekbarClicked
} = useControlDisplayStore.getState();
const onHideTrickPlay = () => {
  setTrickPlayIsVisible(false);
};
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1gw35nr",
  styles: "overflow:hidden;border-radius:0.5rem;background-color:transparent;padding:0px"
} : {
  name: "m2f7fb-SeekbarControls",
  styles: "overflow:hidden;border-radius:0.5rem;background-color:transparent;padding:0px;label:SeekbarControls;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "7whenc",
  styles: "display:flex;width:100%"
} : {
  name: "12bg8tq-SeekbarControls",
  styles: "display:flex;width:100%;label:SeekbarControls;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "1awsv9j",
  styles: "display:flex;--tw-bg-opacity:1;background-color:rgb(255 255 255 / var(--tw-bg-opacity))"
} : {
  name: "pfx4b7-SeekbarControls",
  styles: "display:flex;--tw-bg-opacity:1;background-color:rgb(255 255 255 / var(--tw-bg-opacity));label:SeekbarControls;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "1awsv9j",
  styles: "display:flex;--tw-bg-opacity:1;background-color:rgb(255 255 255 / var(--tw-bg-opacity))"
} : {
  name: "pfx4b7-SeekbarControls",
  styles: "display:flex;--tw-bg-opacity:1;background-color:rgb(255 255 255 / var(--tw-bg-opacity));label:SeekbarControls;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const SeekbarControls = ({
  disabled: isElementDisabled
}: {
  disabled?: boolean;
}) => {
  const playerPosition = usePlayerStore(state => state.playerPosition);
  const isVodPlayerDisabled = usePlayerStore(state => state.isVodPlayerDisabled);
  const seekbarPosition = usePlayerStore(state => state.seekbarPosition);
  const _isSeekbarDisabled = useControlDisplayStore(state => state.isSeekbarDisabled);
  const playerError = usePlayerStore(state => state.playerError);
  const hasPlayerError = !!playerError;
  const isSeekbarClicked = useControlDisplayStore(state => state.isSeekbarClicked);
  const {
    onSeekToThisPosition
  } = usePlayerContext();
  const isSeekbarDisabled = _isSeekbarDisabled || hasPlayerError || isElementDisabled || isVodPlayerDisabled;
  /**
   * Handles pointer down event on the seekbar.
   *
   * This function is called when the user clicks or taps on the seekbar.
   * It calculates the new position of the player based on the click position
   * and seeks to that position.
   **/
  const onPointerDownOnSeekbar: (event: any) => void = (event: React.PointerEvent) => {
    setIsSeekbarClicked(true);
    const stream = usePlayerStore.getState().playerProps.stream;
    increaseCount(stream?.uid || '', 'player_seek_bar_count');
    const targetElem = event.currentTarget;
    if (
    // This condition is used to seek by clicking on the seek bar
    // The click can sometimes be on the child
    !targetElem || ![targetElem?.id, targetElem?.parentElement?.id].includes(PLAYER_CONTROLS_SEEKBAR_WRAPPER_ID)) {
      return;
    }
    const cSeekBar = (document.getElementById(PLAYER_CONTROLS_SEEKBAR_WRAPPER_ID) as HTMLDivElement);
    if (!cSeekBar) return;
    const touchPosition = Math.floor(event.clientX);
    const seekBarClientRect = cSeekBar.getBoundingClientRect();
    const seekBarStyle = getComputedStyle(cSeekBar);
    const seekBarWidth = parseFloat(seekBarStyle.width);
    let cursiorPosition = touchPosition - seekBarClientRect.left;
    if (touchPosition < seekBarClientRect.left) {
      cursiorPosition = 0;
    } else if (touchPosition > seekBarClientRect.left + seekBarWidth) {
      cursiorPosition = seekBarWidth;
    }
    const progress = Math.round(cursiorPosition / seekBarWidth * 1000000) / 10000;
    const playerDuration = usePlayerStore.getState().playerDuration;
    const newPlayerPosition = playerDuration * progress / 100;
    onSeekToThisPosition({
      position: newPlayerPosition,
      duration: playerDuration,
      progress: progress
    });
  };

  /**
   * Handles pointer moves on the seekbar.
   *
   * This function calculates the progress of the seekbar based on the touch position,
   * updates the player position, and sets the trick play duration and visibility.
   **/
  const onPointerMovesOnSeekbar = ({
    touchPosition
  }: {
    touchPosition: number;
  }) => {
    const cSeekBar = (document.getElementById(PLAYER_CONTROLS_SEEKBAR_WRAPPER_ID) as HTMLDivElement);
    if (!cSeekBar || isSeekbarClicked) return;
    const seekBarClientRect = cSeekBar.getBoundingClientRect();
    const seekBarStyle = getComputedStyle(cSeekBar);
    const seekBarWidth = parseFloat(seekBarStyle.width);
    let cursiorPosition = touchPosition - seekBarClientRect.left;
    if (touchPosition < seekBarClientRect.left) {
      cursiorPosition = 0;
    } else if (touchPosition > seekBarClientRect.left + seekBarWidth) {
      cursiorPosition = seekBarWidth;
    }
    const progress = Math.round(cursiorPosition / seekBarWidth * 1000000) / 10000;
    const playerDuration = usePlayerStore.getState().playerDuration;
    const newPlayerPosition = playerDuration * progress / 100;
    setTrickPlayDuration(newPlayerPosition);
    // Pointer moves here here here
    setTrickPlayIsVisible(true);
    onHandleTrickPlayProgressPosition({
      cursiorPosition,
      seekBarWidth,
      screen_isDesktop: true
    });
  };
  const onMouseMoveCB: React.MouseEventHandler<HTMLDivElement> = event => {
    const touchPosition = event.clientX;
    onPointerMovesOnSeekbar({
      touchPosition: Math.floor(touchPosition)
    });
  };
  const onTouchMoveCB: React.TouchEventHandler<HTMLDivElement> = event => {
    const touch = event?.touches[0] || event?.changedTouches[0];
    const touchPosition = touch?.pageX;
    onPointerMovesOnSeekbar({
      touchPosition: Math.floor(touchPosition)
    });
  };
  useEffect(() => {
    if (!isSeekbarClicked) return;
    /**
     * Handles scrubbing event on the seekbar.
     * - called when user scrubs the seekbar
     * Seeks to a new position in the player on every scrub.
     * Handles trick play progress position.
     **/
    const onScrubHandler: any = (event: React.PointerEvent) => {
      const touchPosition = event.clientX;
      const cSeekBar = (document.getElementById(PLAYER_CONTROLS_SEEKBAR_WRAPPER_ID) as HTMLDivElement);
      if (!cSeekBar || !isSeekbarClicked) return;
      const seekBarClientRect = cSeekBar.getBoundingClientRect();
      const seekBarStyle = getComputedStyle(cSeekBar);
      const seekBarWidth = parseFloat(seekBarStyle.width);
      let cursiorPosition = touchPosition - seekBarClientRect.left;
      if (touchPosition < seekBarClientRect.left) {
        cursiorPosition = 0;
      } else if (touchPosition > seekBarClientRect.left + seekBarWidth) {
        cursiorPosition = seekBarWidth;
      }
      const progress = Math.round(cursiorPosition / seekBarWidth * 1000000) / 10000;
      const playerDuration = usePlayerStore.getState().playerDuration;
      const newPlayerPosition = playerDuration * progress / 100;
      setTrickPlayDuration(newPlayerPosition);
      // Pointer moves here here here
      setTrickPlayIsVisible(true);
      onHandleTrickPlayProgressPosition({
        cursiorPosition,
        seekBarWidth,
        screen_isDesktop: true
      });
      onSeekToThisPosition({
        position: newPlayerPosition,
        duration: playerDuration,
        progress: progress
      });

      // setCurrentPos(newPlayerPosition) // dummy scroll | just update seekbar
    };

    const onPointerLeaves = () => {
      onHideTrickPlay();
      setIsSeekbarClicked(false);
    };
    document.addEventListener('pointermove', onScrubHandler);
    document.addEventListener('pointerup', onPointerLeaves);
    return () => {
      document.removeEventListener('pointermove', onScrubHandler);
      document.removeEventListener('pointerup', onPointerLeaves);
    };
  }, [isSeekbarClicked]);
  return _jsx("div", {
    className: "player-controls-wrapper",
    children: _jsxs("div", {
      "aria-disabled": isSeekbarDisabled,
      "aria-valuenow": playerPosition,
      id: PLAYER_CONTROLS_SEEKBAR_WRAPPER_ID,
      onPointerDown: isSeekbarDisabled ? undefined : onPointerDownOnSeekbar,
      onMouseLeave: isSeekbarDisabled ? undefined : onHideTrickPlay,
      onMouseMove: isSeekbarDisabled ? undefined : onMouseMoveCB,
      onTouchEnd: isSeekbarDisabled ? undefined : onTouchMoveCB,
      onTouchMove: isSeekbarDisabled ? undefined : onTouchMoveCB,
      style: {
        opacity: isSeekbarDisabled ? 0.5 : 1
      },
      role: "slider",
      tabIndex: 0,
      children: [isSeekbarDisabled ? _jsx(_Fragment, {}) : _jsx("div", {
        className: "time-on-hover",
        css: _ref,
        id: HOVER_TIME_MS_ID,
        children: _jsx(TrickPlay, {})
      }), _jsxs("div", {
        className: "player-controls-seek-bar",
        "aria-disabled": isSeekbarDisabled,
        css: _ref2,
        children: [_jsx("div", {
          style: {
            transition: isSeekbarClicked ? 'none' : 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
            flex: seekbarPosition
          },
          css: _ref3
        }), _jsx("div", {
          style: {
            transition: isSeekbarClicked ? 'none' : 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
            flex: 100 - seekbarPosition
          },
          css: _ref4
        })]
      }), _jsx("button", {
        "aria-disabled": isSeekbarDisabled,
        className: "player-controls-scrub",
        onPointerDown: isSeekbarDisabled ? undefined : onPointerDownOnSeekbar,
        style: {
          visibility: isSeekbarDisabled ? 'hidden' : 'visible',
          left: `${seekbarPosition}%`,
          transition: isSeekbarClicked ? 'none' : 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)'
        },
        tabIndex: -1
      })]
    })
  });
};
export default SeekbarControls;