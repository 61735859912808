import { PLAYER_TYPE } from '@components/VideoPlayer/constants';
import { useControlDisplayStore } from '@components/VideoPlayer/store/useControlDisplayStore';
import { usePlayerStore } from '@components/VideoPlayer/store/usePlayerStore';
import { useStreamStore } from '@stores/streamStore';
import { PlayerState } from 'amazon-ivs-player';
import { useEffect, useRef, useState } from 'react';
import * as workerTimers from 'worker-timers';
import { IPlayerResponse } from './usePlayer';
const {
  setPlayerPosition,
  setSeekbarPosition,
  setActivePlayerType
} = usePlayerStore.getState();
const {
  setIsPlaying
} = useStreamStore.getState();
const {
  resetControlSectionVisiblity
} = useControlDisplayStore.getState();
const usePlayerPlayPauseState = ({
  vodPlayerRef,
  livePlayerRef
}: {
  isFirstChunkLoaded: boolean;
  vodPlayerRef: IPlayerResponse;
  livePlayerRef: IPlayerResponse;
}) => {
  const [counterToPause, setCounterToPause] = useState(0);
  const playerError = usePlayerStore(state => state.playerError);
  const isEnded = usePlayerStore(state => state.isEnded);
  const isPlaying = useStreamStore(state => state.isPlaying);
  const {
    stream
  } = usePlayerStore(state => state.playerProps);
  const activePlayerType = usePlayerStore(state => state.activePlayerType);
  const isVodPlayerDisabled = usePlayerStore(state => state.isVodPlayerDisabled);
  const _isSeekbarDisabled = useControlDisplayStore(state => state.isSeekbarDisabled);
  const isNotPresent = isEnded || !!playerError;
  const isChannelLive = stream?.status === 20;
  const isLivePlayerActive = activePlayerType === PLAYER_TYPE.LIVE;
  const videoPausedOnRef = useRef(0);
  const activePlayerRef = useRef(isLivePlayerActive ? livePlayerRef : vodPlayerRef);
  activePlayerRef.current = isLivePlayerActive ? livePlayerRef : vodPlayerRef;
  const inActivePlayerRef = useRef(!isLivePlayerActive ? livePlayerRef : vodPlayerRef);
  inActivePlayerRef.current = !isLivePlayerActive ? livePlayerRef : vodPlayerRef;
  const isLivePlayerAvailable = isChannelLive && !livePlayerRef.error && livePlayerRef.isReady;
  const isVodPlayerAvailable = !vodPlayerRef.error && vodPlayerRef.isReady;
  const isPlayerActuallyPaused = activePlayerRef.current.activeState === PlayerState.IDLE || activePlayerRef.current.activeState === PlayerState.READY;
  const isVideoInitialized = isLivePlayerActive ? isLivePlayerAvailable : isVodPlayerAvailable;
  const hasPlayerError = !!playerError;
  const isSeekbarDisabled = _isSeekbarDisabled || hasPlayerError || isVodPlayerDisabled;

  /**
   * @description: This will handle player seeking | seekbar + keyboard seek + backtolive
   * @readonly: Important
   */
  const onSeekToThisPosition = (params: {
    position: number;
    duration: number;
    progress: number;
    delta?: number;
  }) => {
    if (isSeekbarDisabled || !isVodPlayerAvailable) {
      return;
    }
    let newPosition, duration, progress;
    const currentPosition = usePlayerStore.getState().playerPosition;
    const playerDuration = usePlayerStore.getState().playerDuration;
    if (typeof params.delta === 'number') {
      duration = playerDuration;
      newPosition = currentPosition + params.delta;
      newPosition = Math.min(Math.max(newPosition, 0), duration);
      // So next change will auto add these value
      setPlayerPosition(newPosition);
      progress = Math.round(newPosition / duration * 10000000) / 100000;
    } else {
      duration = params.duration;
      newPosition = params.position;
      progress = params.progress;
    }
    newPosition = Math.min(Math.max(newPosition, 0), duration); // Normalize new position

    // only shift if more than 3 seconds elapsed
    let isMajorChangeInShift = Math.abs(newPosition - currentPosition) > 3;
    const isUserNearToEndDuration = Math.abs(duration - newPosition) < 7;
    if (duration && newPosition && progress == -1) {
      isMajorChangeInShift = true;
      progress = Math.round(newPosition / duration * 10000000) / 100000;
    }
    setSeekbarPosition(progress);
    if (!isMajorChangeInShift) {
      return;
    }
    resetControlSectionVisiblity();
    if (isUserNearToEndDuration && isChannelLive) {
      // Shift player to LIVE & return
      setActivePlayerType(PLAYER_TYPE.LIVE);
      setIsPlaying(true);
      vodPlayerRef.setMuted(true);
      vodPlayerRef.pause();
      // Handling new player audio state
      const {
        volume,
        isMuted
      } = usePlayerStore.getState();
      livePlayerRef.setVolume(volume);
      livePlayerRef.setMuted(isMuted);
      livePlayerRef.play();
      return;
    }
    if (isLivePlayerActive) {
      setPlayerPosition(newPosition);
    }
    setActivePlayerType(PLAYER_TYPE.VOD);
    setIsPlaying(true);
    vodPlayerRef.seekTo(newPosition);
    livePlayerRef.setMuted(true);
    livePlayerRef.pause();
    // Handling new player audio state
    const {
      volume,
      isMuted
    } = usePlayerStore.getState();
    vodPlayerRef.setVolume(volume);
    vodPlayerRef.setMuted(isMuted);
    vodPlayerRef.play();
  };
  const SeekToRef = useRef(onSeekToThisPosition);
  SeekToRef.current = onSeekToThisPosition;

  // Handle when & if player got paused due to some unexpected reason, then set zustand state to pause as well
  useEffect(() => {
    if (isNotPresent || !isVideoInitialized) {
      // Player should automatically paused here
      return;
    }
    if (isPlaying !== isPlayerActuallyPaused) {
      setCounterToPause(0);
      return;
    }
    if (counterToPause === 5) {
      // make player state to zustand
      setIsPlaying(!isPlayerActuallyPaused);
      const timer = workerTimers.setTimeout(() => setCounterToPause(0), 0);
      return () => {
        workerTimers.clearTimeout(timer);
      };
    }
    if (counterToPause < 5) {
      const timer = workerTimers.setTimeout(() => {
        setCounterToPause(x => x + 1);
      }, 500);
      return () => {
        workerTimers.clearTimeout(timer);
      };
    }
  }, [counterToPause, isNotPresent, isPlayerActuallyPaused, isPlaying, isVideoInitialized]);
  useEffect(() => {
    if (!isVideoInitialized) {
      return;
    }
    if (!isPlaying) {
      videoPausedOnRef.current = usePlayerStore.getState().playerPosition;
      activePlayerRef.current.pause();
      inActivePlayerRef.current.pause();
      return;
    }
    const newPosition = videoPausedOnRef.current - 1; // start from 1 second earlier
    videoPausedOnRef.current = 0;
    if (!isVodPlayerDisabled && isVodPlayerAvailable && !isSeekbarDisabled && isChannelLive && isLivePlayerActive && newPosition >= 0) {
      const playerDuration = usePlayerStore.getState().playerDuration;
      const isUserNotNearNewEnd = Math.abs(playerDuration - newPosition) < 7;
      if (!isUserNotNearNewEnd) {
        SeekToRef.current({
          position: newPosition,
          duration: playerDuration,
          progress: -1
        });
        return;
      }
    }
    activePlayerRef.current.play();
  }, [isVodPlayerDisabled, isChannelLive, isLivePlayerActive, isPlaying, isSeekbarDisabled, isVideoInitialized, isVodPlayerAvailable]);
  useEffect(() => {
    if (!isPlaying) {
      // Don't do anything if player is already paused
      return;
    }
    // only for iOS visibility
    //if client platform is iOS
    if (!/iPad|iPhone|iPod/.test(navigator.platform)) {
      return;
    }
    const vallback = () => {
      const isPlayerMuted = usePlayerStore.getState().isMuted;
      if (document.visibilityState === 'hidden' && isPlayerMuted) {
        setIsPlaying(false);
      }
      if (document.visibilityState === 'visible') {
        setIsPlaying(true);
      }
    };
    document.addEventListener('visibilitychange', vallback);
    return () => {
      document.removeEventListener('visibilitychange', vallback);
    };
  }, [isPlaying]);
  const [retryPlayPauseCount, setRetryPlayPauseCount] = useState(false);
  const isIdlePlayerState = activePlayerRef.current.activeState === PlayerState.IDLE;
  const isPlayingPlayerState = activePlayerRef.current.activeState === PlayerState.PLAYING;
  useEffect(() => {
    if (isPlayingPlayerState) {
      setRetryPlayPauseCount(true);
    }
  }, [isPlayingPlayerState]);
  useEffect(() => {
    const isCurrentlyPlaying = useStreamStore.getState().isPlaying;
    if (!isCurrentlyPlaying || !retryPlayPauseCount) {
      // player in pause state
      return;
    }
    if (!isIdlePlayerState) return;
    const timer = workerTimers.setTimeout(() => {
      setRetryPlayPauseCount(false);
      activePlayerRef.current.play();
    }, 250);
    return () => {
      workerTimers.clearTimeout(timer);
    };
  }, [isIdlePlayerState, retryPlayPauseCount]);
  return {
    onSeekToThisPosition
  };
};
export default usePlayerPlayPauseState;