import { jsx as _jsx } from "@emotion/react/jsx-runtime";
function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1nalyzl",
  styles: "overflow:hidden;@media (min-width: 768px){overflow:auto;}"
} : {
  name: "1dfeuhb-PageWrapper",
  styles: "overflow:hidden;@media (min-width: 768px){overflow:auto;};label:PageWrapper;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "1qmr6ab",
  styles: "overflow:auto"
} : {
  name: "1dlvffu-PageWrapper",
  styles: "overflow:auto;label:PageWrapper;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "9tn8cj",
  styles: "display:flex;flex-direction:column;--tw-bg-opacity:1;background-color:rgb(5 5 6 / var(--tw-bg-opacity));font-weight:400;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity))"
} : {
  name: "12lq8t-PageWrapper",
  styles: "display:flex;flex-direction:column;--tw-bg-opacity:1;background-color:rgb(5 5 6 / var(--tw-bg-opacity));font-weight:400;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));label:PageWrapper;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export const PageWrapper = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return _jsx("div", {
    css: _ref,
    children: _jsx("div", {
      css: _ref2,
      children: _jsx("div", {
        css: _ref3,
        children: children
      })
    })
  });
};